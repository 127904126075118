// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import VerifyView from '@/views/VerifyView.vue';
import ApiFetcher from '@/views/ApiFetcher.vue';
import SaleSmartly from '@/views/SaleSmartly.vue';
import Chat from '@/views/Chat.vue';
import Chat2 from '@/views/Chat2.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: SaleSmartly,
  },
  
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
  },
  {
    path: '/api-fetcher',
    name: 'api-fetcher',
    component: ApiFetcher,
  },
  {
    path: '/verify',
    name: 'Verify',
    component: VerifyView,
  },
  {
    path: '/WhatSapp356',
    name: 'WhatSapp356',
    component: Chat,
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: Chat, // A fallback component for 404 errors
  }

];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// tite change on route change WhatsApp
router.afterEach((to) => {
  document.title = 'WhatsApp | ' + to.name;
});

export default router;
